.custom-dropdown .dropdown-toggle.nav-link {
  padding: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.tail-header .active--link:after,
.navbar-nav .active--link:after {
  content: "";
  display: block;
  height: 3px;
  background-color: var(--primary);
  width: 100%;
  position: absolute;
  border: 0;
  bottom: -7px;
  left: 0;
  border-radius: 50px;
}

.navbar-nav .active--link svg {
  fill: var(--primary);
}

@media screen and (max-width: 580px) {
  .header .custom-dropdown.right .dropdown-menu {
    right: auto !important;
  }
}

#root[aria-hidden="true"] .header {
  padding-right: 7px;
}

.primary--loader .ui.inverted.dimmer .ui.loader:after {
  border-color: var(--primary) transparent transparent;
}
.primary--loader {
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
}
