.menu-accordion .MuiAccordionSummary-content {
  margin: 0 !important;
}
.menu-accordion .MuiAccordionSummary-root {
  background-color: var(--white) !important;
  padding: 0px 5px;
}
.menu-accordion .MuiAccordion-rounded {
  border-radius: 0px !important;
}
.menu-accordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}
.list-items {
  background-color: var(--lightGray);
}
