:root {
  /* --primary: #168bfc; */
  --primary: #685bc7;
  --lightPrimary: #e7f3ff;
  --black: #1a1a1a;
  --white: #fff;
  --darkGray: #65676b;
  --gray: #9da4b4;
  --lightGray: #edf0f4;
  --warning: #e67e22;
  --danger: #e74c3c;
  --success: #198754;
  --gold: #ffd700;
  --orange: #ff6720;
  --green100: #a7e163;
  --red100: #ee2737;
  --pink100: #ef426f;
}

.primary {
  color: var(--primary);
}

.important\:primary {
  color: var(--primary) !important;
}

.fill\:primary-svg .svg,
.fill\:primary-all-svg * {
  color: var(--primary) !important;
}

.hover\:primary-all-text:hover * {
  color: var(--primary) !important;
}

.hover\:primary-only-text:hover p {
  color: var(--primary) !important;
}

.focus\:primary--text:focus,
.hover\:primary--text:hover {
  color: var(--primary) !important;
}

.bg--primary {
  background-color: var(--primary) !important;
}

.important\:bg--primary {
  background-color: var(--primary) !important;
}

.focus\:bg-red100:focus,
.hover\:bg-red100:hover,
.bg-red100 {
  background-color: var(--red100);
}

.lightPrimary {
  color: var(--lightPrimary);
}

.bg--lightPrimary {
  background-color: var(--lightPrimary);
}

.important\:bg--lightPrimary {
  background-color: var(--lightPrimary) !important;
}

.black {
  color: var(--black);
}

.focus\:black:focus,
.hover\:black:hover {
  color: var(--black) !important;
}

.hover\:black-all:hover * {
  color: var(--black);
}

.hover\:black-all-text:hover p {
  color: var(--black);
}

.bg--fullBlack {
  background-color: #000;
}

.white {
  color: var(--white);
}

.important\:white {
  color: var(--white) !important;
}

.bg--white {
  background-color: var(--white);
}

.important\:white-all * {
  color: var(--white) !important;
}

.hover\:color-white:hover {
  color: var(--white) !important;
}

.hover\:color-white-all:hover * {
  color: var(--white) !important;
}

.darkGray {
  color: var(--darkGray);
}

.bg--darkGray {
  background-color: var(--darkGray);
}

.gray {
  color: var(--gray);
}

.bg--gray {
  background-color: var(--gray);
}

.important\:bg--gray {
  background-color: var(--gray) !important;
}

.lightGray {
  color: var(--lightGray);
}

.bg--lightGray {
  background-color: var(--lightGray);
}

.important\:bg--lightGray {
  background-color: var(--lightGray) !important;
}

.warning {
  color: var(--warning);
}

.bg--warning {
  background-color: var(--warning);
}

.danger {
  color: var(--danger);
}

.bg--danger {
  background-color: var(--danger);
}

.focus\:bg--danger:focus,
.hover\:bg--danger:hover {
  background-color: var(--danger);
}

.success {
  color: var(--success);
}

.bg--success {
  background-color: var(--success);
}

.important\:bg--success {
  background-color: var(--success) !important;
}

.gold {
  color: var(--gold);
}

.bg--gold {
  background-color: var(--gold);
}

/* border :start */
.important\:border {
  border: 1px solid !important;
}

.border--primary {
  border-color: var(--primary) !important;
}

.border--success {
  border-color: var(--success);
}

.border-lightGray {
  border-color: var(--lightGray) !important;
}

.border-danger {
  border-color: var(--danger) !important;
}

/* border :end */

.bg-primary-chip {
  color: var(--primary) !important;
  background-color: rgba(22, 139, 252, 0.1) !important;
}

.overlay\:bg--gray-200 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.overlay\:bg--gray-400 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/* hover css :start */

.hover\:bg--lightGray:hover {
  background-color: var(--lightGray);
}

.hover\:bg--gray-200:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.hover\:bg--primary:hover {
  background-color: var(--primary);
}

.hover\:bg--lightPrimary:hover {
  background-color: var(--lightPrimary);
}

/* hover css :end */

/* focus css :start */
.focus\:bg--lightGray:active,
.focus\:bg--lightGray:focus {
  background-color: var(--lightGray);
}

.focus\:bg--primary:focus {
  background-color: var(--primary);
}

.focus\:bg--lightPrimary:focus {
  background-color: var(--lightPrimary);
}

.focus\:scale-90:focus {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}

/* focus css :end */

/* ::backdrop :start */
.custom-backdrop .MuiBackdrop-root {
  background-color: rgb(240, 242, 245, 0.6) !important;
}

.custom-backdrop .MuiPaper-root {
  border-radius: 0.75rem !important;
}

/* ::backdrop :end */

.MuiCheckbox-colorPrimary {
  color: var(--primary) !important;
}