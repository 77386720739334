.post .right .dropdown-menu {
  right: 0 !important;
  inset: 0px auto auto auto !important;
}
.like-reactions.hover--show .reactions {
  z-index: 11;
  opacity: 1;
  transition: 0.3s;
  /* top: -11px; */
  transform: translateY(-51px);
}
.reactions {
  position: absolute;
  /* top: 30px; */
  transform: translateY(20px);
  z-index: -11;
  left: 0;
  opacity: 0;
  height: 50px;
  transition-delay: 0.3s;
  animation-timing-function: ease-in-out;
}
.reactions img {
  transition: all 0.3s ease-in-out;
  animation-timing-function: ease-in-out;
}
.reactions img:hover {
  transform: scale(1.4);
}
