.timelineDot {
  content: "";
  width: 2px;
  height: 95%;
  display: block;
  background-color: var(--gray);
  border-radius: 130px;
  position: absolute;
  left: -6px;
}

.timelineDot::before {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-color: var(--gray);
  border-radius: 130px;
  position: absolute;
  left: -2px;
}
.timelineDot::after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-color: var(--gray);
  border-radius: 130px;
  position: absolute;
  left: -2px;
  bottom: 0;
}
