.leftsiderbar {
  height: calc(100vh - 50px);
  max-width: 315px;
  width: 100%;
}

/* scrollbar :start */

/* Handle */
.leftsiderbar#scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.leftsiderbar#scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
}
