.custom-accordion.MuiAccordion-root.Mui-expanded,
.custom-accordion .MuiAccordionSummary-content {
    margin: 0 !important;
}

.custom-accordion .MuiButtonBase-root.MuiIconButton-root {
    padding: 0 8px !important;
    border: none !important;
}

.custom-accordion .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
}

.custom-accordion .MuiAccordionSummary-root {
    border-radius: 8px !important;
    padding: 8px 10px 8px 4px !important;
}

.custom-accordion.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.custom-accordion .MuiButtonBase-root {
    /* background-color: #f3f4f6 !important; */
    border: 1.5px solid #f3f4f6 !important;
}

.custom-accordion.MuiAccordion-root:before {
    display: none !important;
}