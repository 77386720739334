.MuiInput-underline:before {
  display: none;
}
.MuiSelect-select.MuiSelect-select {
  padding-left: 7px;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--primary) !important;
}

.css-yk16xz-control {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  border: none !important;
  border-radius: 10px !important;
}
.css-1wa3eu0-placeholder {
  font-family: Light;
}
.css-2b097c-container:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
