.comment-box {
  background-color: var(--lightGray);
}

.hover_show_box .hover_show_drpdwn {
  position: absolute;
}

.hover_show_box .hover_dots_drpdwn {
  opacity: 0;
}

.hover_show_box:hover .hover_dots_drpdwn {
  opacity: 1;
}
