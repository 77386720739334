@font-face {
  font-family: Light;
  src: url(../../assets/fonts/Rubik-Light.ttf);
}

@font-face {
  font-family: Regular;
  src: url(../../assets/fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: Medium;
  src: url(../../assets/fonts/Rubik-Medium.ttf);
}

.Light {
  font-family: Light;
}

.Light-all * {
  font-family: Light !important;
}

.Regular {
  font-family: Regular;
}

.important\:Regular {
  font-family: Regular !important;
}
.important\:Regular-all * {
  font-family: Regular !important;
}

.Medium {
  font-family: Medium;
}

h1,
h2,
h3,
h4 {
  font-family: Medium;
  font-weight: unset;
  line-height: 1.2;
}

h5,
h6 {
  font-family: Medium;
  font-weight: unset;
  line-height: 1.2;
}

label {
  font-family: Medium;
  font-weight: unset;
}
b,
strong {
  font-family: Medium;
  font-weight: unset;
}

button {
  font-family: Regular;
  font-size: 15px !important;
  font-weight: unset;
}
a,
input,
li,
p,
textarea {
  font-family: Regular;
  line-height: 1.4285em;
  font-size: 15px;
}

small,
span {
  font-family: Light;
  font-size: 15px;
  line-height: 22px;
}

/* font sizes :start */
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}
/* font sizes :end */
