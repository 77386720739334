.select\:full-width .visible.menu.transition {
  min-width: 300px !important;
  width: 100% !important;
}
.visible.menu.transition {
  border-color: transparent !important;
}

/* scrollbar :start */
/* width */
.visible.menu.transition::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.visible.menu.transition::-webkit-scrollbar-track {
  background: transparent;
  width: 7px;
}

/* Handle */
.visible.menu.transition::-webkit-scrollbar-thumb {
  background: #bcc0c4;
}

/* Handle on hover */
.visible.menu.transition::-webkit-scrollbar-thumb:hover {
  opacity: 0.4;
}
/* scrollbar :end */
