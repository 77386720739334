.Notification .notifi_items:hover .notifi_dots {
  display: flex;
  position: absolute;
  z-index: 11;
  right: 20px;
  top: 15px;
}
.Notification .notifi_items .notifi_unread {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 15px;
}
.Notification .notifi_dots {
  display: none;
}
