/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-278 {
    height: 278px;
  }
}

body {
  margin: 0;
}

html {
  padding-right: 0 !important;
}

.blur-xs {
  --tw-blur: blur(2px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}