.rightsiderbar {
  height: calc(100vh - 50px);
  max-width: 316px;
  width: 100%;
}

/* scrollbar :start */

/* Handle */
.rightsiderbar#scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.rightsiderbar#scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
}
