.chatwindow {
  position: fixed;
  bottom: 0;
  right: 30px;
}
.chatwindow-user {
  position: fixed;
  bottom: 0;
  transition: 0.2s;
  right: 32rem;
}
.chatwindow-all-threads {
  transition: 0.2s;
}
