.access-info-tabs .nav-pills .nav-link.active,
.access-info-tabs .nav-pills .show > .nav-link {
  background-color: transparent;
  color: var(--primary);
  border-radius: 0;
}

.access-info-tabs .nav-pills .nav-link {
  font-family: Regular;
  color: var(--darkGray);
}
