.custom-picker .MuiInput-underline:after,
.custom-picker .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3f51b5;
  pointer-events: none;
  display: none;
}

.custom-picker * {
  font-family: Regular !important;
}
.custom-picker .MuiFormControl-root {
  width: 100%;
}
