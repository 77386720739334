* {
  font-family: Regular;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
body {
  background-color: #f0f2f5;
  padding-right: 0 !important;
  overflow-y: scroll;
}
#root[aria-hidden="true"] {
  margin-right: 7px;
}
textarea::placeholder,
input::placeholder {
  font-family: Light !important;
  color: var(--darkGray);
  font-size: 14px;
}
button:disabled {
  background-color: #9da4b4 !important;
}
.outline-0:focus-visible,
input:focus-visible {
  outline: -webkit-focus-ring-color auto 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: #1a1a1a;
}
a {
  text-decoration: none;
  padding: 0;
}
a:hover {
  color: var(--primary);
}
.scrollbar::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}
.scrollbar::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar-track {
  background: 0 0;
  border-radius: 30px;
  width: 7px;
}
.scrollbar::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #bcc0c4;
}
.scrollbar::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  opacity: 0.4;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 00.75rem;
  padding-right: 00.75rem;
}
.container--small {
  max-width: 940px;
}
.rounded--lg {
  border-radius: 0.5rem !important;
}
.rounded--xl {
  border-radius: 0.75rem !important;
}
.rounded--t--xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.rounded--b--xl {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}
.rounded--full {
  border-radius: 9999px !important;
}
.max-w-xxs {
  max-width: 10rem;
}
.important\:mr-0 {
  margin-right: 0 !important;
}
.important\:mb-0 {
  margin-bottom: 0 !important;
}
.important\:p-0 {
  padding: 0 !important;
}
.important\:flex {
  display: flex !important;
}
.important\:flex-row {
  flex-direction: row !important;
}
.important\:shadow-none {
  box-shadow: none !important;
}
.custom-dropdown .dropdown-toggle::after {
  display: none;
}
.custom-dropdown .dropdown-menu {
  padding: 0 0;
  margin: 0;
  border: none;
}
.custom-dropdown.right .dropdown-menu {
  right: 0 !important;
}
.divider {
  height: 1px;
  background-color: var(--lightGray);
}
.divider.text {
  height: 0;
}
.btn.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}
@media (max-width: 640px) {
  .xs\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .xs\:p-4 {
    padding: 1rem !important;
  }
  .xs\:p-0 {
    padding: 0 !important;
  }
}
.important\:w-full {
  width: 100% !important;
}
.important\:h-full {
  height: 100% !important;
}
.important\:min-w--full {
  min-width: 100% !important;
}
.important\:h--screen {
  height: 100vh !important;
}
.important\:rounded--lg {
  border-radius: 0.5rem !important;
}
.important\:rounded--xl {
  border-radius: 0.75rem !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 10px;
}
.PrivateNotchedOutline-root-1 {
  top: -1px !important;
  border-radius: 8px !important;
}
.MuiFormLabel-root.Mui-focused {
  color: var(--primary) !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
}
