.compose {
  padding: 6px;
  display: flex;
  align-items: center;
  background-color: var(--lightGray);
  position: fixed;
  width: calc(100% - 10px);
  margin: 5px;
  border-radius: 10px;
  bottom: 4px;
  border: none;
}

.compose-input {
  flex: 1;
  padding-left: 10px;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::placeholder {
  opacity: 0.8;
}
